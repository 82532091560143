// import node module libraries
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Fragment, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// import sub components
import Calendar from "components/calendar/Calendar";
import AnSummary from "components/dashboard/analytics/AnSummary";
import { DashboardContext } from "context/Context";
import Select from "react-select";
import CommonHeaderTabs from "../CommonHeaderTabs";

// import BudgetCard from "./BudgetCard";
// import BudgetCategoryCard from "./BudgetCategoryCard";
// import BudgetDetailsCard from "./BudgetDetailsCard";
// import ExpensesChartCard from "./ExpensesChartCard";
const GasCalendar = () => {
  const db = getFirestore();

  const { user, company, setCompany } = useContext(DashboardContext);

  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [loading, setLoading] = useState(true);

  const [magnaProfits, setMagnaProfits] = useState([]);
  const [premiumProfits, setPremiumProfits] = useState([]);
  const [magnaLiters, setMagnaLiters] = useState([]);
  const [premiumLiters, setPremiumLiters] = useState([]);
  const [magnaSales, setMagnaSales] = useState([]);
  const [premiumSales, setPremiumSales] = useState([]);
  const [dateSArray, setdateSArray] = useState([]);
  const [magnaPrices, setMagnaPrices] = useState([]);
  const [premiumPrices, setPremiumPrices] = useState([]);
  const [magnaCosts, setMagnaCosts] = useState([]);
  const [premiumCosts, setPremiumCosts] = useState([]);

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const [magnaStats, setMagnaStats] = useState({
    averageSales: 0,
    averageLiters: 0,
    averageProfit: 0,
    totalProfit: 0,
  });
  const [premiumStats, setPremiumStats] = useState({
    averageSales: 0,
    averageLiters: 0,
    averageProfit: 0,
    totalProfit: 0,
  });

  const initial = async () => {
    let startOfMonth = new Date(new Date().getFullYear(), currentMonth, 0);
    let endOfMonth = new Date(new Date().getFullYear(), currentMonth + 1, 0);
    var resp = [];
    const filesRef = collection(db, "companies", user.companyId, "dayInformation");
    const q = query(filesRef, where("date", ">=", startOfMonth), where("date", "<=", endOfMonth));
    const filesSnap = await getDocs(q);
    filesSnap.forEach((e) => {
      resp.push(e.data());
    });

    setData(resp);
  };

  const getLocs = async () => {
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    console.log(resp);
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("d; ", data[0]);
    if (selectedLocation.value && data.length > 0) {
      console.log("selectedLocation: ", selectedLocation.value);
      let totalMagnaProfit = 0;
      let totalMagnaLitersSold = 0;
      let totalMagnaIncome = 0;

      let totalPremiumProfit = 0;
      let totalPremiumLitersSold = 0;
      let totalPremiumIncome = 0;

      let totalInfoDays = 0;

      let mProfits = [];
      let pProfits = [];
      let mLiters = [];
      let pLiters = [];
      let mSales = [];
      let pSales = [];
      let mPrices = [];
      let pPrices = [];
      let mCosts = [];
      let pCosts = [];

      let dsArray = [];

      data.forEach((a) => {
        // Loop through each locationId in the object

        let locationData = a[selectedLocation.value.locId] || {};

        // Calculate profit for Magna, only if the values are defined
        if (
          locationData.magnaSalesIncome !== undefined &&
          locationData.magnaLitersSold !== undefined &&
          locationData.magnaPrice !== undefined &&
          a.magnaCost !== undefined // Check if magnaCost is defined
        ) {
          let magnaProfit =
            locationData.magnaSalesIncome - locationData.magnaLitersSold * a.magnaCost;
          totalMagnaProfit += magnaProfit;
          totalMagnaLitersSold += locationData.magnaLitersSold;
          totalMagnaIncome += locationData.magnaSalesIncome;
          totalInfoDays++;

          mProfits.push(magnaProfit);
          mLiters.push(locationData.magnaLitersSold);
          mSales.push(locationData.magnaSalesIncome);
          mPrices.push(locationData.magnaPrice);
          mCosts.push(a.magnaCost);

          dsArray.push(new Date(a.date.seconds * 1000).toLocaleDateString("es-MX"));
        }

        // Calculate profit for Premium, only if the values are defined
        if (
          locationData.premiumSalesIncome !== undefined &&
          locationData.premiumLitersSold !== undefined &&
          locationData.premiumPrice !== undefined &&
          a.premiumCost !== undefined // Check if premiumCost is defined
        ) {
          let premiumProfit =
            locationData.premiumSalesIncome - locationData.premiumLitersSold * a.premiumCost;
          totalPremiumProfit += premiumProfit;
          totalPremiumLitersSold += locationData.premiumLitersSold;
          totalPremiumIncome += locationData.premiumSalesIncome;

          pProfits.push(premiumProfit);
          pLiters.push(locationData.premiumLitersSold);
          pSales.push(locationData.premiumSalesIncome);
          pPrices.push(locationData.premiumPrice);
          pCosts.push(a.premiumCost);
        }
      });

      setMagnaProfits(mProfits);
      setPremiumProfits(pProfits);
      setMagnaLiters(mLiters);
      setPremiumLiters(pLiters);
      setMagnaSales(mSales);
      setPremiumSales(pSales);
      setdateSArray(dsArray);
      setMagnaPrices(mPrices);
      setPremiumPrices(pPrices);
      setMagnaCosts(mCosts);
      setPremiumCosts(pCosts);

      // Calculate the averages for Magna
      const averageMagnaProfit = totalMagnaProfit / totalInfoDays;
      const averageMagnaLitersSold = totalMagnaLitersSold / totalInfoDays;
      const averageMagnaIncome = totalMagnaIncome / totalInfoDays;

      // Calculate the averages for Premium
      const averagePremiumProfit = totalPremiumProfit / totalInfoDays;
      const averagePremiumLitersSold = totalPremiumLitersSold / totalInfoDays;
      const averagePremiumIncome = totalPremiumIncome / totalInfoDays;

      setMagnaStats({
        averageSales: averageMagnaIncome,
        averageLiters: averageMagnaLitersSold,
        averageProfit: averageMagnaProfit,
        totalProfit: totalMagnaProfit,
      });
      setPremiumStats({
        averageSales: averagePremiumIncome,
        averageLiters: averagePremiumLitersSold,
        averageProfit: averagePremiumProfit,
        totalProfit: totalPremiumProfit,
      });
    }
  }, [data, selectedLocation, currentMonth]);

  useEffect(() => {
    setLoading(true);
    initial()
      .then(() => {
        getLocs();
      })
      .then(() => {
        setLoading(false);
      });
  }, [change, currentMonth]);

  return (
    <Fragment>
      {/* page header tabs */}
      <CommonHeaderTabs />
      {/* <BudgetCard /> */}
      <Card className="mb-4">
        <div className="w-100 p-3">
          <div className="d-flex align-items-baseline justify-content-between">
            {!loading && businessLocations.length > 0 && (
              <div className="d-flex justify-content-start align-items-center mt-2">
                <div className="d-flex align-items-start flex-column border-end">
                  <p className="me-2 mb-0 text-success">Magna</p>
                  <p className="me-2 mb-0">
                    Ventas promedio: ${magnaStats.averageSales.toLocaleString()}
                  </p>
                  <p className="me-2 mb-0">
                    Litros promedio: {magnaStats.averageLiters.toLocaleString()}
                  </p>
                  <p className="me-2 mb-0">
                    Utilidad promedio: ${magnaStats.averageProfit.toLocaleString()}
                  </p>
                  <p className="me-2 mb-0">
                    Utilidad total: ${magnaStats.totalProfit.toLocaleString()}
                  </p>
                </div>
                <div className="d-flex align-items-start flex-column ms-2">
                  <p className="me-4 mb-0 text-danger">Premium</p>
                  <p className="me-4 mb-0">
                    Ventas promedio: ${premiumStats.averageSales.toLocaleString()}
                  </p>
                  <p className="me-4 mb-0">
                    Litros promedio: {premiumStats.averageLiters.toLocaleString()}
                  </p>
                  <p className="me-4 mb-0">
                    Utilidad promedio: ${premiumStats.averageProfit.toLocaleString()}
                  </p>

                  <p className="me-4 mb-0">
                    Utilidad total: ${premiumStats.totalProfit.toLocaleString()}
                  </p>
                </div>
              </div>
            )}

            <div>
              <h5 className="mb-0 card-title me-4">Selecciona la ubicación</h5>

              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                defaultValue={selectedLocation}
                placeholder="Selecciona"
                value={selectedLocation}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: "40px",
                    minHeight: "40px",
                    minWidth: "15rem",
                    borderRadius: "10px",
                    borderColor: "#ced4da",
                  }),
                }}
                // options={[
                //   { value: user, label: user.email },
                //   { value: "strawberry", label: "Strawberry" },
                //   { value: "vanilla", label: "Vanilla" },
                // ]}
                options={businessLocations.map((e) => {
                  return { value: e, label: e.name };
                })}
                onChange={(e) => {
                  setSelectedLocation(e);
                }}
              />
            </div>
          </div>
        </div>

        <Card.Body>
          <Calendar
            data={data}
            selectedLocation={selectedLocation.value}
            callbackFn={(res) => {
              setChange(!change);
            }}
            currentMonthFn={(res) => {
              console.log("res: ", res);
              setCurrentMonth(res);
            }}
          />
        </Card.Body>
      </Card>
      {/* total budget, spend and remaining  */}

      <div className="w-100 d-flex justify-content-between mb-4 pt-3">
        <h3>Ventas Graficadas</h3>
        <div>
          <h5 className="mb-0 card-title me-4">Selecciona la ubicación</h5>

          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isClearable={false}
            defaultValue={selectedLocation}
            placeholder="Selecciona"
            value={selectedLocation}
            styles={{
              control: (base) => ({
                ...base,
                height: "40px",
                minHeight: "40px",
                minWidth: "15rem",
                borderRadius: "10px",
                borderColor: "#ced4da",
              }),
            }}
            // options={[
            //   { value: user, label: user.email },
            //   { value: "strawberry", label: "Strawberry" },
            //   { value: "vanilla", label: "Vanilla" },
            // ]}
            options={businessLocations.map((e) => {
              return { value: e, label: e.name };
            })}
            onChange={(e) => {
              setSelectedLocation(e);
            }}
          />
        </div>
      </div>
      <AnSummary
        ventaData={magnaSales}
        litrosData={magnaLiters}
        utilidadData={magnaProfits}
        xAxisData={dateSArray}
        priceData={magnaPrices}
        costData={magnaCosts}
        title="Magna"
      />

      <br />

      <AnSummary
        ventaData={premiumSales}
        litrosData={premiumLiters}
        utilidadData={premiumProfits}
        xAxisData={dateSArray}
        priceData={premiumPrices}
        costData={premiumCosts}
        title="Premium"
      />

      {/* expenses chart and budget categories table cards */}
      <Row>
        <Col lg={6} className="mb-4">
          {/* <ExpensesChartCard /> */}
        </Col>
        <Col lg={6} className="mb-4">
          {/* <BudgetCategoryCard /> */}
        </Col>
      </Row>

      {/* budget details table card */}
      {/* <BudgetDetailsCard /> */}
    </Fragment>
  );
};

export default GasCalendar;
